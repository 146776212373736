import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Section from "../components/section"
import styled from "styled-components"
import SEO from "../components/seo"

const StyledDate = styled.h2`
  font-size: 1em;
  font-style: italic;
  margin-bottom: 3em;
`
const BlogContent = styled.div`
  text-align: ${props => props.textAlign || "center"};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto
`

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.excerpt} />
      <Section
        backgroundColor={"#d4f3ef"}
        title={frontmatter.title}
        height={"auto"}
        overflowY={"auto"}
      >
        <StyledDate>{frontmatter.date}</StyledDate>
        <BlogContent
          textAlign={frontmatter.textAlign}
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        excerpt
        textAlign
      }
    }
  }
`
